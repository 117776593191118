import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import {
  isEveryCookieEnabled,
  OPTANON_CONSENT_COOKIE,
} from '@thg-commerce/enterprise-core/src/utils/CookieHelper'
import { sanitizeCookie } from '@thg-commerce/enterprise-utils'

import { CookieMessageFallback } from './CookieMessageFallback'
import { EmbedWrapper } from './style'
import { GenericEmbedProps } from './types'

interface YouTubeProps extends GenericEmbedProps {
  title?: string
}

const ConsentCheck = (): boolean => {
  const { youtubeConsentRequired, showOptanonFooterLink } = useSiteConfig()
  if (!youtubeConsentRequired || !showOptanonFooterLink) {
    return true
  }

  const optanonConsentCookie = sanitizeCookie(OPTANON_CONSENT_COOKIE)
  return optanonConsentCookie
    ? isEveryCookieEnabled(optanonConsentCookie)
    : false
}

const parseYouTubeUrl = (url: string) => {
  const regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\/”"'\&\?]*).*/
  const match = url.match(regex)
  if (match && match[2].length === 11) {
    return match[2]
  }
  return ''
}

export const YouTube = (props: YouTubeProps) => {
  const [cookieConsent, setCookieConsent] = React.useState<boolean | null>(null)

  React.useEffect(() => {
    setCookieConsent(ConsentCheck)

    const oldWrapper = window.OptanonWrapper
    window.OptanonWrapper = () => {
      oldWrapper?.()

      window.Optanon.OnConsentChanged(() => {
        setCookieConsent(ConsentCheck)
      })
    }
  }, [])

  if (cookieConsent === null) {
    return null
  }

  if (!cookieConsent) {
    return <CookieMessageFallback />
  }

  const videoId = parseYouTubeUrl(props.url)
  const iframeSrc = `//youtube.com/embed/${videoId}?autoplay=0`

  return (
    <EmbedWrapper>
      <iframe
        title={props.title || 'YouTube Video'}
        src={iframeSrc}
        frameBorder="0"
        width={props.width || '640px'}
        height={props.height || '390px'}
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        tabIndex={0}
      />
    </EmbedWrapper>
  )
}
