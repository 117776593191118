import * as React from 'react'
import dynamic from 'next/dynamic'

import { i18n, useSiteConfig } from '@thg-commerce/enterprise-core'
import { withTheme } from '@thg-commerce/enterprise-theme'
import { isADAChatAvailable } from '@thg-commerce/enterprise-utils/src/ADAChat/ADAChat'
import { TextStyle } from '@thg-commerce/gravity-theme'

const GravityCircle = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/GravityCircle'),
  { loading: () => <div style={{ width: 24, height: 24 }} /> },
)
import {
  ActiveSearchText,
  BottomContainer,
  ChatIcon,
  ChatTextContainer,
  Container,
  IconContainer,
  OnlineText,
  OnlineTextContainer,
  StyledLiveChatButton,
  SubtitleLine,
  TopContainer,
} from './styles'

interface ButtonStateStyleInterface {
  button: {
    backgroundColor: string
    border: {
      width: string
      color: string
    }
    text: {
      style: TextStyle
      color: string
      textDecoration: string
    }
    boxShadow: {
      shadowX: string
      shadowY: string
      shadowBlur: string
      shadowSpread: string
      shadowColor: string
    }
  }
}
export interface StartLiveChatCTABasketStyleInterface {
  default: ButtonStateStyleInterface
  hover: ButtonStateStyleInterface
  focus: ButtonStateStyleInterface
  active: ButtonStateStyleInterface
}

export const ADAChat = withTheme((props) => {
  const { hasAdaChatbot } = useSiteConfig()

  if (!hasAdaChatbot) {
    return null
  }

  const i18nText = {
    liveChat: i18n('component.adachat.title.text'),
    operators: i18n('component.adachat.operators.text'),
    online: i18n('component.adachat.online.text'),
    connectionTime: i18n('component.adachat.info.text'),
    startChat: i18n('component.adachat.chat.button.text'),
  }

  const [isActive, setIsActive] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (typeof document === 'undefined') return

    const checkADAChatAvailability = () => {
      setIsActive(isADAChatAvailable(document))
    }

    checkADAChatAvailability()

    const observer = new MutationObserver(() => {
      checkADAChatAvailability()
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  const triggerADAChatPerson = () => {
    try {
      if (isActive && typeof window !== 'undefined' && window.adaEmbed) {
        window.adaEmbed.toggle()
      } else {
        console.warn('ADA Chat is not available or not initialized yet.')
      }
    } catch (error) {
      console.error('Error triggering ADA Chat:', error)
    }
  }

  return (
    <React.Fragment>
      {isActive && (
        <Container>
          <TopContainer>
            <ChatTextContainer>
              <IconContainer
                iconContainerStyleOverride={props.iconContainerStyleOverride}
              >
                {props.theme.site.favicon ? (
                  <ChatIcon
                    chatIconStyleOverride={props.chatIconStyleOverride}
                  />
                ) : (
                  <GravityCircle />
                )}
              </IconContainer>
              <div>
                <SubtitleLine liveChatTextEntry={props.liveChatTextEntry}>
                  {i18nText.liveChat}
                </SubtitleLine>
                <OnlineTextContainer>
                  {i18nText.operators}
                  <OnlineText onlineTextStyle={props.onlineTextStyle}>
                    {i18nText.online}
                  </OnlineText>
                </OnlineTextContainer>
              </div>
            </ChatTextContainer>
          </TopContainer>
          <BottomContainer
            containerStyleOverride={props.containerStyleOverride}
          >
            <ActiveSearchText>{i18nText.connectionTime}</ActiveSearchText>
            <StyledLiveChatButton
              startChatCTAStyle={props.startChatCTAStyle}
              onClick={triggerADAChatPerson}
            >
              {i18nText.startChat}
            </StyledLiveChatButton>
          </BottomContainer>
        </Container>
      )}
    </React.Fragment>
  )
})
