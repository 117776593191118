import { Button, Input, PlatformMessage } from '@thg-commerce/gravity-elements'
import { mq, spacing, styled, Text } from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    width: 100%;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const DiscountButton = styled(Button)`
  display: block;
  border-radius: 0px;
  margin-left: -1px;

  &:hover {
    line-height: 24px;
    display: block;
    border-radius: 0px;
  }

  &:focus {
    border-radius: 0px;
  }

  &:focus-within {
    border-radius: 0px;
  }

  button {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    line-height: 24px;
    border-radius: 0px;

    &:hover,
    &:focus {
      line-height: 24px;
      border-radius: 0px;
    }
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: 0;
  }
`

export const DiscountInputContainer = styled.div`
  flex: 1;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-top: 0;
  }
`

export const StyledInput = styled(Input)`
  flex: 1;
  border-radius: 0px;
`

export const StyledPlatformMessage = styled(PlatformMessage)`
  margin-top: ${spacing(1)};
`

export const LabelContainer = styled.div`
  margin-bottom: ${spacing(1)};
`
export const Label = styled.p`
  ${Text('bodyText', 'alternate')};
`
