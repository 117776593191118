import * as React from 'react'

import { BreakpointArray } from '@thg-commerce/gravity-theme'

import { Carousel, CarouselButtonPlacement } from '../../Carousel'
import { ChevronIconStyle } from '../../Carousel/theme'
import { CarouselI18nText } from '../../Carousel/types'
import VideoPlayer from '../VideoTranscript/VideoSlide'
import { VideoTranscriptModal } from '../VideoTranscript/VideoTranscriptModal'

import { CarouselInnerWrapper, StyledImage } from './styles'
import { ImageCarouselProps } from './types'

const getTranslatedModalText = (
  title: string,
  i18nText?: CarouselI18nText,
) => ({
  title,
  closeI18nText: {
    closeAriaLabel: i18nText?.closeI18nText.closeAriaLabel,
    closeLabel: i18nText?.closeI18nText.closeLabel,
  },
})

const getControls = (
  isVideo: boolean,
  propControl:
    | {
        visible?: BreakpointArray<boolean>
        placement?: CarouselButtonPlacement
        chevronIconStyle?: ChevronIconStyle
      }
    | undefined,
) => {
  if (!propControl) return undefined

  if (isVideo) {
    return {
      ...propControl,
      visible: [true] as BreakpointArray<boolean>,
      placement: CarouselButtonPlacement.Split,
    }
  }

  return propControl
}

export const ImageCarousel = (props: ImageCarouselProps) => {
  const [modalTitle, setModalTitle] = React.useState('')
  const isVideoSplitControls = props.isVideoSplitControls ?? false

  const [isTranscriptOpen, setIsTranscriptOpen] = React.useState(false)
  const [activeTranscript, setActiveTranscript] = React.useState<string>()
  const carouselItems = React.useMemo(() => {
    return props.urls.map((url, index) => {
      if (url.isVideoUrl) {
        return (
          <VideoPlayer
            key={url.url}
            url={url}
            onTranscriptClick={() => {
              setActiveTranscript(url.videoTranscript)
              setModalTitle(url.videoTitle || '')
              setIsTranscriptOpen(true)
            }}
          />
        )
      }
      return (
        <StyledImage
          width="669"
          height="669"
          key={url.url}
          src={[
            {
              url: url.url,
            },
          ]}
          alt={url.alt}
          isAmp={false}
          tag={url.tag}
          aspectRatio={props.aspectRatio}
          fetchPriority={
            props.onHydrateVisibleFetchPriority &&
            index < (props.itemsPerSlide || 1)
              ? props.onHydrateVisibleFetchPriority
              : undefined
          }
          imageClassName={props.imageClassName}
        />
      )
    })
  }, [props.urls, props.aspectRatio, props.onHydrateVisibleFetchPriority])

  return (
    <React.Fragment>
      <CarouselInnerWrapper fullscreen={props.fullscreen}>
        <Carousel
          items={carouselItems}
          i18n={props?.i18nText}
          indicatorStyle="hidden"
          itemsPerSlide={props.itemsPerSlide || 1}
          hideSlidePreview={true}
          hideControls={!isVideoSplitControls}
          loopSlides={true}
          slideTo={props.slideTo}
          zoomEnabled={props.zoomEnabled}
          zoomClickable={props.zoomClickable}
          zoomOnClick={props.zoomOnClick}
          swipeable={true}
          onChange={props.onChange}
          style={{ height: '100%' }}
          itemGapSpacing={props.itemGapSpacing}
          controls={getControls(isVideoSplitControls, props.controls)}
          overflow={props.overflow}
          onSwipe={props.onSwipe}
          onNavClick={props.onNavClick}
          onThumbnailClick={props.onThumbnailClick}
        />
      </CarouselInnerWrapper>

      {isTranscriptOpen && (
        <VideoTranscriptModal
          i18nText={getTranslatedModalText(modalTitle, props.i18nText)}
          content={activeTranscript}
          onClose={() => setIsTranscriptOpen(false)}
        />
      )}
    </React.Fragment>
  )
}
