import * as React from 'react'

import { useI18n, useSiteConfig } from '@thg-commerce/enterprise-core'
import { SafeHtml, Separator } from '@thg-commerce/gravity-elements'
import {
  spacing,
  styled,
  Text,
  TextEntry,
  TextStyle,
} from '@thg-commerce/gravity-theme'

const InformationSection = styled.div`
  display: block;
  margin-bottom: ${spacing(1)};
`

const StyledSafeHtml = styled(SafeHtml)<{
  textStyle?: { entry: TextEntry; style: TextStyle }
}>`
  &,
  a {
    ${(props) =>
      Text(
        props.textStyle?.entry || 'bodyText',
        props.textStyle?.style || 'alternate',
      )}
  }
`

const Title = styled.h2<{ textStyle?: { entry: TextEntry; style: TextStyle } }>`
  ${(props) =>
    Text(
      props.textStyle?.entry || 'medium1',
      props.textStyle?.style || 'alternate',
    )}
  margin: ${spacing(1)} 0 ${spacing(1)};
`

export const DeliveryAndReturnsInfo = ({
  showOnlyContent = false,
  deliveryMessageTextStyle,
  returnsTitleTextStyle,
}: {
  showOnlyContent?: boolean
  deliveryMessageTextStyle?: { entry: TextEntry; style: TextStyle }
  returnsTitleTextStyle?: { entry: TextEntry; style: TextStyle }
}) => {
  const {
    showProductDeliveryAndReturnsInformation,
    showDeliveryAndReturnsInfoInAccordion,
  } = useSiteConfig()
  const i18n = useI18n()

  if (
    !showProductDeliveryAndReturnsInformation &&
    !showDeliveryAndReturnsInfoInAccordion
  ) {
    return null
  }

  const i18nText = {
    deliveryTitle: i18n('deliveryandreturnsinfo.delivery.heading'),
    deliveryMessage: i18n('deliveryandreturnsinfo.delivery.message'),
    deliveryLegalMesage: i18n('deliveryandreturnsinfo.delivery.legalmessage'),
    returnsTitle: i18n('deliveryandreturnsinfo.returns.heading'),
    returnsMessage: i18n('deliveryandreturnsinfo.returns.message'),
  }

  return (
    <div data-testid="delivery-and-returns-info">
      <InformationSection>
        {i18nText.deliveryTitle && !showOnlyContent && (
          <Title>{i18nText.deliveryTitle}</Title>
        )}
        {i18nText.deliveryMessage && (
          <StyledSafeHtml
            textStyle={deliveryMessageTextStyle}
            content={i18nText.deliveryMessage}
          />
        )}
        {i18nText.deliveryLegalMesage && (
          <SafeHtml content={i18nText.deliveryLegalMesage} />
        )}
      </InformationSection>
      <InformationSection>
        {i18nText.returnsTitle && (
          <Title textStyle={returnsTitleTextStyle}>
            {i18nText.returnsTitle}
          </Title>
        )}
        {i18nText.returnsMessage && (
          <SafeHtml content={i18nText.returnsMessage} />
        )}
      </InformationSection>
      {!showOnlyContent && <Separator withSpacing />}
    </div>
  )
}
