import * as React from 'react'

import { ContentItem, ProductBlock } from '@thg-commerce/enterprise-components'
import { removeCurrencySymbol } from '@thg-commerce/enterprise-components/Price'
import { ProductListRenderer } from '@thg-commerce/enterprise-components/ProductListRenderer/ProductListRenderer'
import { getPowerReviewGroups } from '@thg-commerce/enterprise-components/src/PowerReview/PowerReview'
import {
  i18n,
  useFormattableI18nProperty,
  useSessionSettings,
  useSiteConfig,
} from '@thg-commerce/enterprise-core'
import { RecommendationTrackingContext } from '@thg-commerce/enterprise-metrics/src/backend_event/types/tracking'
import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import {
  productEventsCategory,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { PRODUCT_CONTENT_KEY } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Product'
import {
  FulfilmentMethod,
  ProductMarketedSpecialOffer,
  ProductVariant,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { ProductListItem } from '@thg-commerce/enterprise-widget-product-list-page/src/styles'
import { ProductPriceProps } from '@thg-commerce/gravity-elements'
import { ColourChoice } from '@thg-commerce/gravity-elements/Swatch'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { Carousel } from '@thg-commerce/gravity-patterns'
import {
  AmpCarouselProps,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns/Carousel/types'

import { ProductRecommendationsThemeInterface } from '../theme'

export interface ProductRecommendationsProps {
  products: {
    url: string
    title: string
    brand: {
      name: string
      imageUrl?: string
    }
    colourSwatches?: ColourChoice[]
    sku: number
    externalIdentifier?: string
    marketedSpecialOffer?: ProductMarketedSpecialOffer
    price: ProductPriceProps
    image: string
    reviews: {
      averageScore: number
      total: number
    }
    inStock?: boolean
    isCheckStock?: boolean
    isOrderInStore?: boolean
    defaultVariant?: ProductVariant
    content?: ContentItem[]
  }[]
  itemsPerSlide: number | number[]
  trackingContext?: RecommendationTrackingContext
  recommendationsStyle?: ProductRecommendationsThemeInterface
  showPdpLinkWhenOutOfStock?: boolean
  displayAsGrid?: boolean
  widgetTitle?: string
  overrideStyleWidth?: boolean
  carouselSize?: number
}

const processedItemVariantId = (
  useExternalIdentifier: boolean,
  useExternalIdentifierInSchema: boolean,
  item: {
    defaultVariant: {
      externalIdentifier: string
      sku: number
    }
  },
) =>
  useExternalIdentifier || useExternalIdentifierInSchema
    ? item.defaultVariant?.externalIdentifier || ''
    : item.defaultVariant?.sku || ''

const processedGA4Data = (
  products,
  useExternalIdentifier,
  useExternalIdentifierInSchema,
  widgetTitle,
  alternateProductKeysForCategories,
) => {
  return products.map((item, index) => ({
    index,
    item_id:
      (useExternalIdentifier || useExternalIdentifierInSchema) &&
      item?.externalIdentifier
        ? item.externalIdentifier
        : item?.sku || '',
    item_name: item.title,
    item_brand: item.brand?.name || '',
    price: removeCurrencySymbol(item.price.price.defaultPrice),
    quantity: 1,
    item_list_name: widgetTitle,
    item_variant: processedItemVariantId(
      useExternalIdentifier,
      useExternalIdentifierInSchema,
      item,
    ),
    ...productEventsCategory(
      item.categories || item.content,
      alternateProductKeysForCategories,
    ),
  }))
}

export const ProductRecommendations = (props: ProductRecommendationsProps) => {
  const {
    enableClickAndCollect,
    useGA4EnhancedEcom,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    showPdpLinkWhenOutOfStock,
    alternateProductKeysForCategories,
  } = useSiteConfig()

  const sessionSettings = useSessionSettings()

  React.useEffect(() => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Viewed',
        eventCategory: 'Product | Recommendations',
        eventLabel: 'Recommendations rails component',
      },
    })

    if (useGA4EnhancedEcom) {
      pushToEventGA4({
        event: 'ecom_event',
        event_name: 'view_item_list',
        ecommerce: {
          currencyCode: sessionSettings?.currency,
          items: processedGA4Data(
            props.products,
            useExternalIdentifier,
            useExternalIdentifierInSchema,
            props.widgetTitle,
            alternateProductKeysForCategories,
          ),
        },
      })
    }
  }, [
    alternateProductKeysForCategories,
    props.products,
    props.widgetTitle,
    sessionSettings?.currency,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    useGA4EnhancedEcom,
  ])

  const i18nText = {
    buttonI18nText: {
      buyAriaLabel: useFormattableI18nProperty('general.productbuynow.label'),
      buyText: i18n('general.productbuynow.text'),
      quickBuyText: i18n('general.product.quickbuy.text'),
      soldOutText: i18n('general.productsoldout.text'),
    },
    reviewsI18nText: {
      reviewsStarsLabel: i18n('reviews.stars.label'),
      reviewsLabel: i18n('reviews.reviews.label'),
    },
    carouselText: {
      leftScrollLabel: i18n('carousel.controls.left.arialabel'),
      rightScrollLabel: i18n('carousel.controls.right.arialabel'),
    },
    swatchI18nText: {
      swatchTooltipUnavailable: i18n(
        'product.productoptions.swatch.tooltip.unavailable.text',
      ),
      swatchTooltipCloseButton: i18n(
        'product.productoptions.swatch.tooltip.close.text',
      ),
    },
    closeI18nText: {
      closeAriaLabel: i18n('general.modal.close.button.arialabel'),
      closeLabel: i18n('general.modal.close.button.label'),
    },
    freeGiftMessage: i18n('product.marketedspecialoffer.freegift.text'),
  }

  const ampProps: AmpCarouselProps = {
    height: 500,
    width: 300,
    type: 'slides',
    role: 'region',
  }

  const trackProductClick = (index: number) => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked',
        eventCategory: 'Product | Recommendations',
        eventLabel: 'Recommendations product clicked position',
        eventLabelValue: `${index}`,
      },
    })
  }

  const trackQuickBuyClick = (index: number) => {
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked',
        eventCategory: 'Product | Recommendations',
        eventLabel: 'Recommendations product buy position',
        eventLabelValue: `${index}`,
      },
    })
  }

  const getFulfilmentMethodIconsProps = (recs, enableClickAndCollect, i18n) => {
    if (!enableClickAndCollect) return undefined

    return {
      enableClickAndCollect,
      inStock: Boolean(recs.defaultVariant?.inStock) || false,
      isCheckStock: Boolean(recs.defaultVariant?.isCheckStock) || false,
      isOrderInStore: Boolean(recs.defaultVariant?.isOrderInStore) || false,
      leadTime: recs.defaultVariant?.leadTime || undefined,
      weightGroups: recs.defaultVariant?.weightGroups || [],
      isBookable: Boolean(recs.defaultVariant?.isBookable) || false,
      inStockLocations: recs.defaultVariant?.inStockLocations || [],
      iconsAvailability: {
        homeDelivery: Boolean(
          recs.defaultVariant?.eligibleForFulfilmentMethods?.includes(
            FulfilmentMethod.HomeDelivery,
          ),
        ),
        storeAvailable: Boolean(
          recs.defaultVariant?.eligibleForFulfilmentMethods?.includes(
            FulfilmentMethod.CollectInStore,
          ),
        ),
        storeDelivery: Boolean(
          recs.defaultVariant?.eligibleForFulfilmentMethods?.includes(
            FulfilmentMethod.DeliverToStore,
          ),
        ),
      },
      i18nText: {
        clickAndCollect: {
          isAvailable: i18n('product.list.clickandcollect.text'),
          isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
        },
        homeDelivery: {
          isAvailable: i18n('product.delivery.home.instock.text'),
          isNotAvailable: i18n('product.delivery.home.unavailable.text'),
          isOutOfStock: i18n('product.delivery.home.outofstock.text'),
          datedDelivery: i18n(
            'basket.item.fulfilment.leadtime',
            recs.defaultVariant?.leadTime?.toString(),
          ),
          nextDayDelivery: i18n('basket.item.fulfilment.nextdaydelivery'),
          oneManDelivery: i18n(
            'product.item.fulfilment.1man.nextdaydeliveryavailable',
          ),
          outOfGaugeDelivery: i18n(
            'product.item.fulfilment.outofgauge.nameddaydeliveryavailable',
          ),
          dynamicDelivery: i18n(
            `product.item.fulfilment.pdp.${recs.defaultVariant?.weightGroups?.[0]?.toLowerCase()}`,
          ),
        },
        storeDelivery: {
          isAvailable: i18n('product.delivery.store.available.text'),
        },
        orderInStore: {
          isAvailable: i18n(
            'product.item.fulfilment.orderinstore.available.text',
          ),
        },
      },
    }
  }

  const productBlocks = React.useMemo(() => {
    const getButtonData = (product: {
      inStock?: boolean | null
      title: string
    }) => {
      const inStock =
        'inStock' in product &&
        product.inStock !== undefined &&
        product.inStock !== null
          ? product.inStock
          : true
      return inStock
        ? {
            title: i18nText.buttonI18nText.buyText,
            ariaLabel: i18nText.buttonI18nText.buyAriaLabel(product.title),
            quickBuyTitle: i18nText.buttonI18nText.quickBuyText,
            productInStock: product.inStock || false,
          }
        : {
            title: i18nText.buttonI18nText.soldOutText,
            quickBuyTitle: '',
            disabled: !showPdpLinkWhenOutOfStock,
          }
    }

    return props.products.map((recs, index) => {
      const marketedSpecialOfferData =
        recs.marketedSpecialOffer || recs.defaultVariant?.marketedSpecialOffer
      return (
        <ProductBlock
          {...recs}
          sku={recs?.sku?.toString()}
          externalIdentifier={recs?.externalIdentifier}
          widgetName={props.widgetTitle || ''}
          marketedSpecialOffer={
            (props.recommendationsStyle?.paps.display &&
              marketedSpecialOfferData?.title && {
                title: marketedSpecialOfferData?.title.content[0].content,
                description:
                  marketedSpecialOfferData?.description?.content[0].content ||
                  '',
                i18nText: {
                  closeI18nText: i18nText.closeI18nText,
                  freeGiftMessage: i18nText.freeGiftMessage,
                },
                onlyDisplayOfferBadge: true,
              }) ||
            undefined
          }
          title={{ value: recs.title, useAlternateStyle: false }}
          url={
            props.trackingContext
              ? `${recs.url}?rctxt=${props.trackingContext}`
              : recs.url
          }
          image={{
            urls: { largeProduct: recs.image },
          }}
          swatch={
            recs.colourSwatches
              ? {
                  shape: SwatchShape.CIRCLE,
                  size: SwatchSize.Small,
                  colours: recs.colourSwatches,
                  i18nText: {
                    unavailableText:
                      i18nText.swatchI18nText.swatchTooltipUnavailable,
                    closeButtonText:
                      i18nText.swatchI18nText.swatchTooltipCloseButton,
                  },
                }
              : undefined
          }
          review={{
            starRating: recs.reviews.averageScore,
            numberOfReviews: recs.reviews.total,
            screenReaderOnlyText: `${recs.reviews.averageScore} ${i18nText.reviewsI18nText.reviewsStarsLabel} 
    ${recs.reviews.total} ${i18nText.reviewsI18nText.reviewsLabel}`,
          }}
          button={getButtonData(recs)}
          key={`${recs.title}-${index}`}
          fromRecommendations={true}
          fulfilmentMethodIconsProps={getFulfilmentMethodIconsProps(
            recs,
            enableClickAndCollect,
            i18n,
          )}
          onClickEventEmitter={() => trackProductClick(index)}
          onQuickBuyClickEventEmitter={() => trackQuickBuyClick(index)}
          attributes={props.recommendationsStyle?.productBlock?.attributes}
          content={recs.content}
          powerReviewGroups={getPowerReviewGroups(recs.content)}
        />
      )
    })
  }, [
    i18nText.buttonI18nText,
    showPdpLinkWhenOutOfStock,
    enableClickAndCollect,
    i18nText.closeI18nText,
    i18nText.freeGiftMessage,
    i18nText.reviewsI18nText.reviewsLabel,
    i18nText.reviewsI18nText.reviewsStarsLabel,
    i18nText.swatchI18nText.swatchTooltipCloseButton,
    i18nText.swatchI18nText.swatchTooltipUnavailable,
    props.products,
    props.recommendationsStyle?.paps.display,
    props.recommendationsStyle?.productBlock?.attributes,
    props.trackingContext,
    props.widgetTitle,
  ])

  const productBlocksforGrid = React.useMemo(() => {
    return productBlocks.map((block) => (
      <ProductListItem>{block}</ProductListItem>
    ))
  }, [productBlocks])

  if (props.displayAsGrid) {
    return (
      <ProductListRenderer
        productBlocks={productBlocksforGrid}
        oneProductPerRow={true}
        fourProductsPerRowDesktop={true}
      />
    )
  }

  return (
    <Carousel
      items={productBlocks}
      itemsPerSlide={props.itemsPerSlide}
      i18n={i18nText.carouselText}
      isAmp={false}
      ampProps={ampProps}
      hideControlsOnSingleSlide={true}
      hideControls={false}
      indicatorStyle={props.recommendationsStyle?.carousel?.indicatorStyle}
      controls={{
        placement: CarouselButtonPlacement.BottomRight,
        size: props.carouselSize,
      }}
      itemGapSpacing={props.recommendationsStyle?.carousel?.itemGapSpacing}
      ignoreGapOffset={props.overrideStyleWidth}
    />
  )
}
