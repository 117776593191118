import styled from 'styled-components'

import { Image } from '@thg-commerce/gravity-elements'
import {
  BreakpointArray,
  css,
  Direction,
  mediaQueryRenderer,
  mq,
  spacing,
} from '@thg-commerce/gravity-theme'

const thumbnailWrapperDirectionMap = {
  [Direction.ROW]: css`
    height: 100%;
    overflow: hidden auto;
  `,
  [Direction.COLUMN]: css`
    overflow: auto hidden;
  `,
}

export const ProductImageThumbnailsWrapper = styled.div<{
  direction: BreakpointArray<Direction>
  fullScreen?: boolean
}>`
  display: flex;

  ${(props) =>
    mediaQueryRenderer(
      props.direction,
      (direction) => thumbnailWrapperDirectionMap[direction],
    )}

  ${(props) =>
    props.fullScreen &&
    css`
      padding: ${spacing(2)};
      @media (orientation: landscape) {
        height: 100%;
        overflow: hidden auto;
      }
    `}
`

const thumbnailListDirectionMap = {
  [Direction.ROW]: css`
    flex-direction: column;
    margin-right: ${spacing(2)};
  `,

  [Direction.COLUMN]: css`
    flex-direction: row;
  `,
}

export const StyledThumbnailList = styled.ul<{
  direction: BreakpointArray<Direction>
  fullScreen?: boolean
}>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    max-height: 380px;
    min-height: 100%;
    overflow-y: auto;
    position: relative;
    left: 0;
  }

  ${(props) =>
    mediaQueryRenderer(
      props.direction,
      (direction) => thumbnailListDirectionMap[direction],
    )}

  ${(props) =>
    props.fullScreen &&
    `@media (orientation: landscape) {
      flex-direction:column;
  }`}
`

// These orientation values refer to the ImageGallery orientation, not the layout of thumbnails

const listItemHorizontal = css`
  flex-basis: 0;
  max-width: 82px;
`

const listItemVertical = css`
  max-width: 152px;
  flex-shrink: 0;
`

const listItemDirectionMap = {
  [Direction.COLUMN]: listItemHorizontal,
  [Direction.ROW]: listItemVertical,
}

export const ProductImageWrapperListItem = styled.li<{
  direction: BreakpointArray<Direction>
  size: BreakpointArray<number>
  fullScreen?: boolean
  aspectRatio?: string
}>`
  margin-top: 0;
  height: 100%;

  ${(props) =>
    !props.fullScreen &&
    mediaQueryRenderer(
      props.size,
      (size) => `
        width: ${size + 4}px;
      `,
    )}

  ${(props) =>
    mediaQueryRenderer(
      props.direction,
      (direction) => listItemDirectionMap[direction],
    )}

    ${(props) =>
    props.fullScreen &&
    `@media (orientation: landscape) {
          ${listItemHorizontal}
      }`}
`

export const StyledButton = styled.button<{ selected: boolean }>`
  border: solid 2px
    ${(props) =>
      props.selected
        ? `${
            props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
            props.theme.colors.palette.brand.base
          };`
        : `${props.theme.colors.palette.greys.light};`};

  &:hover,
  &:focus {
    outline: none;
    border: solid 2px
      ${(props) =>
        props.selected
          ? props.theme.elements.squaredRadioInput.alternateStyle?.outline ||
            props.theme.colors.palette.brand.base
          : props.theme.colors.palette.greys.dark};
  }
`

export const StyledImage = styled(Image)<{
  fullScreen?: boolean
}>`
  && {
    display: block;
    vertical-align: baseline;
    position: static;
    overflow: auto;

    ${(props) =>
      props.fullScreen &&
      `@media (orientation: landscape) {
        width: 72px;
        height: 72px;
      }`}
  }
`
