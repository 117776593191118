import * as React from 'react'

import { LiveChatTagWrapper } from '../styles'

export const displayChatContainer = (hasLivePerson?: boolean) => {
  if (hasLivePerson) {
    return (
      <LiveChatTagWrapper>
        <div className="lp-panel">
          <div id="checkout-lpButtonDiv"></div>
        </div>
      </LiveChatTagWrapper>
    )
  }

  return null
}
