import * as React from 'react'
// import { useMotionValue } from 'framer-motion'
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
} from 'react-zoom-pan-pinch'
import FocusTrap from 'focus-trap-react'
import { vsprintf } from 'sprintf-js'

import { useTheme } from '@thg-commerce/gravity-patterns/theme'
// import { useScrollLock } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  Direction,
  KeyboardKeys,
} from '@thg-commerce/gravity-theme'

import { Carousel } from '../Carousel'

import VideoPlayer from './VideoTranscript/VideoSlide'
import { VideoTranscriptModal } from './VideoTranscript/VideoTranscriptModal'
import { Controls } from './Controls'
import { Scale, useImageGalleryContext } from './ImageGalleryContext'
import { ImageGalleryContextProvider } from './ImageGalleryContextProvider'
import {
  AccessibilityLabel,
  AccessibilityLabelText,
  CarouselControls,
  CarouselWrapper,
  Container,
  ContentWrapper,
  Control,
  Footer,
  Header,
  StyledChevronLeft,
  StyledChevronRight,
  StyledImage,
  StyledImageCarousel,
  StyledTransformWrapper,
  ThumbnailsWrapper,
  Title,
  TitleWrapper,
} from './styles'
import {
  CloseI18nText,
  FullscreenHeaderTitleTabIndex,
  ImageGalleryProps,
  Paginate,
  Position,
} from './types'
import { ImageThumbnails } from './'

export const ImageGalleryWithContext = (props: ImageGalleryProps) => {
  return (
    <ImageGalleryContextProvider
      zoomButtonClickHandler={props.zoomOnClickHandler}
    >
      <ImageGallery {...props} />
    </ImageGalleryContextProvider>
  )
}

const getTranslatedModalText = (title: string, i18n: CloseI18nText) => ({
  title,
  closeI18nText: {
    closeAriaLabel: i18n.closeAriaLabel,
    closeLabel: i18n.closeLabel,
  },
})

const getZoomControlProps = (props: ImageGalleryProps, paginate: Function) => ({
  paginate,
  controlSize: [48] as [number],
  i18nText: {
    zoomInLabel: props.i18nText.zoomInLabel || '',
    zoomOutLabel: props.i18nText.zoomOutLabel || '',
    rightScrollLabel: props.i18nText.carouselI18nText?.rightScrollLabel || '',
    leftScrollLabel: props.i18nText.carouselI18nText?.leftScrollLabel || '',
    exitFullScreenText:
      props.i18nText.fullscreenI18nText?.exitFullscreenText || '',
    enterFullScreenText:
      props.i18nText.fullscreenI18nText?.enterFullscreenText || '',
  },
  showCarouselControls: props.showCarouselControls || [false],
  zoomControlPosition: [Position.BOTTOM_RIGHT_SPACED] as [Position],
  zoomOpen: false,
  controlIcons: props.controlIcons,
})

export const ImageGallery = (props: ImageGalleryProps) => {
  const theme = useTheme()
  const [selectedImageIndex, setSelectedImageIndex] = React.useState(
    props.selectedImageIndex || 0,
  )
  const [updatesToAnnounce, setUpdatesToAnnounce] = React.useState('')
  const [hideScaleAnnouncer, setHideScaleAnnouncer] = React.useState(false)
  const [headerTitleTabIndex, setHeaderTitleTabIndex] = React.useState(
    FullscreenHeaderTitleTabIndex.NotTabbable,
  )
  const fullscreenTitleRef = React.useRef<HTMLParagraphElement>(null)
  const zoomWrapperRefs = React.useRef<(ReactZoomPanPinchContentRef | null)[]>(
    [],
  )
  const [isTranscriptOpen, setIsTranscriptOpen] = React.useState(false)
  const [activeTranscript, setActiveTranscript] = React.useState<string>()
  const [modalTitle, setModalTitle] = React.useState('')
  const [containsVideo, setContainsVideo] = React.useState<boolean>(false)
  // @TODO: [REBUILD-6061] Reimpliment full screen view on image gallery
  // const translateX = useMotionValue(0)
  // const translateY = useMotionValue(0)
  // const setScrollLock = useScrollLock()

  const {
    fullScreenActive: [fullScreenActive, setFullScreenActive],
    scale: [scale, setScale],
    zoomButtonClickHandler,
    showZoomButtonsAccessibilityLabel: [
      showZoomButtonsAccessibilityLabel,
      setShowZoomButtonsAccessibilityLabel,
    ],
  } = useImageGalleryContext()

  React.useEffect(() => {
    setUpdatesToAnnounce(
      vsprintf(props.i18nText.announcerI18nText.imageChangeAnnouncerText, [
        selectedImageIndex + 1,
        props.altText,
      ]),
    )
    setHideScaleAnnouncer(true)
    setScale(Scale.MIN)

    if (zoomWrapperRefs.current[selectedImageIndex]) {
      zoomWrapperRefs.current[selectedImageIndex]?.resetTransform()
    }
  }, [
    props.altText,
    props.i18nText.announcerI18nText.imageChangeAnnouncerText,
    selectedImageIndex,
    setScale,
  ])

  // React.useEffect(() => {
  //   setUpdatesToAnnounce(
  //     vsprintf(
  //       fullScreenActive
  //         ? props.i18nText.announcerI18nText.fullscreenEnterAnnouncerText
  //         : props.i18nText.announcerI18nText.fullscreenExitAnnouncerText,
  //       [props.altText],
  //     ),
  //   )

  //   setHideScaleAnnouncer(true)
  //   setScale(Scale.MIN)
  //   setScrollLock(fullScreenActive, document, window)

  //   if (fullScreenActive && fullscreenTitleRef.current) {
  //     setHeaderTitleTabIndex(FullscreenHeaderTitleTabIndex.Tabbable)
  //     fullscreenTitleRef.current.focus()
  //   }
  // }, [fullScreenActive])

  React.useEffect(() => {
    if (props.updatingUrlsResetSelectedImageIndex) {
      setSelectedImageIndex(0)
    }
  }, [props.updatingUrlsResetSelectedImageIndex, props.urls])

  React.useEffect(() => {
    if (!hideScaleAnnouncer) {
      setUpdatesToAnnounce(
        vsprintf(props.i18nText.announcerI18nText.zoomLevelAnnouncerText, [
          `${scale * 100}%`,
        ]),
      )
    }
    setHideScaleAnnouncer(false)
  }, [
    hideScaleAnnouncer,
    props.i18nText.announcerI18nText.zoomLevelAnnouncerText,
    scale,
  ])

  const zoomCarouselItems = React.useMemo(() => {
    const ZoomControlProps = {
      controlSize: [48] as [number],
      i18nText: {
        zoomInLabel: props.i18nText?.zoomInLabel || '',
        zoomOutLabel: props.i18nText?.zoomOutLabel || '',
        enterFullScreenText:
          props.i18nText.fullscreenI18nText?.enterFullscreenText || '',
      },
      showCarouselControls: props.showCarouselControls || [false],
      zoomControlPosition: [Position.TOP_RIGHT] as [Position],
      zoomOpen: true,
      controlIcons: props.controlIcons,
    }
    return props.urls.zoom.map((url, index) => {
      if (url.isVideoUrl) {
        setActiveTranscript(url.videoTranscript)
        setContainsVideo(true)
        return (
          <VideoPlayer
            url={url}
            onTranscriptClick={() => {
              setActiveTranscript(url.videoTranscript)
              setModalTitle(url.videoTitle || '')
              setIsTranscriptOpen(true)
            }}
            transcriptionButtonAlignment={{
              bottom: '30px',
            }}
          />
        )
      }
      return (
        <div style={{ width: 'fit-content' }}>
          <StyledTransformWrapper
            key={index}
            initialScale={Scale.MIN}
            limitToBounds={true}
            centerZoomedOut={true}
            disablePadding={true}
            maxScale={Scale.MAX}
            minScale={Scale.MIN}
            onTransformed={(_, scale) => setScale(scale.scale)}
            ref={(ref) => (zoomWrapperRefs.current[index] = ref)}
          >
            {(utils) => {
              return (
                <React.Fragment>
                  <Controls
                    {...ZoomControlProps}
                    zoomInOnClick={() => {
                      utils.zoomIn(Scale.STEP)
                    }}
                    zoomOutOnClick={() => {
                      utils.zoomOut(Scale.STEP)
                    }}
                    location={Position.TOP_RIGHT}
                  />
                  <TransformComponent
                    contentStyle={{ height: '100%' }}
                    wrapperStyle={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      onClick={() => utils.zoomIn(Scale.STEP)}
                      style={{ cursor: 'zoom-in', height: '100%' }}
                      role="img"
                    >
                      <StyledImage
                        width="669"
                        height="669"
                        key={url.url}
                        src={[
                          {
                            url: url.url,
                          },
                        ]}
                        alt={url.alt}
                        isAmp={false}
                        aspectRatio={props.aspectRatio}
                      />
                    </div>
                  </TransformComponent>
                </React.Fragment>
              )
            }}
          </StyledTransformWrapper>
        </div>
      )
    })
  }, [
    props.i18nText?.zoomInLabel,
    props.i18nText?.zoomOutLabel,
    props.i18nText.fullscreenI18nText?.enterFullscreenText,
    props.showCarouselControls,
    props.controlIcons,
    props.urls.zoom,
    props.aspectRatio,
    setScale,
  ])

  if (props.urls.images.length === 0) {
    return null
  }

  const paginate = (direction: Paginate) => {
    selectedImageIndex + direction < 0
      ? setSelectedImageIndex(props.urls.images.length - 1)
      : selectedImageIndex + direction > props.urls.images.length - 1
      ? setSelectedImageIndex(0)
      : setSelectedImageIndex(selectedImageIndex + direction)
  }

  const direction: BreakpointArray<Direction> = fullScreenActive
    ? [Direction.COLUMN, Direction.COLUMN, Direction.ROW, Direction.ROW]
    : props.direction

  const carouselSlideControls = (
    <CarouselControls
      onFocus={() => setShowZoomButtonsAccessibilityLabel(false)}
      fullScreen={fullScreenActive}
      carouselControlDisplay={
        theme.patterns.imageGallery.carouselControls.display
      }
    >
      <Control
        onClick={() => {
          paginate(Paginate.Previous)
          props.onNavClick?.('left')
        }}
        aria-label={props.i18nText?.carouselI18nText?.leftScrollLabel || ''}
        data-testid="carousel-fullscreen-control-left"
        size={props.controlSize}
        borderColor={props.controlBorderColor}
        backgroundColor={props.controlBackgroundColor}
      >
        <StyledChevronLeft
          width="24"
          height="24"
          fill={theme.colors.palette.brand.darkest}
        />
      </Control>
      <Control
        onClick={() => {
          paginate(Paginate.Next)
          props.onNavClick?.('right')
        }}
        aria-label={props.i18nText?.carouselI18nText?.rightScrollLabel || ''}
        data-testid="carousel-fullscreen-control-right"
        size={props.controlSize}
        borderColor={props.controlBorderColor}
        backgroundColor={props.controlBackgroundColor}
      >
        <StyledChevronRight
          fill={theme.colors.palette.brand.darkest}
          width="24"
          height="24"
        />
      </Control>
    </CarouselControls>
  )

  // const controlSize: BreakpointArray<number> = fullScreenActive
  //   ? [48]
  //   : props.controlSize

  // const keyboardPan = (event: React.KeyboardEvent) => {
  //   if (carouselWidth !== 0) {
  //     const bounds = (carouselWidth * scale - carouselWidth) / 2
  //     switch (event.key) {
  //       case 'ArrowLeft':
  //         event.preventDefault()
  //         translateX.set(Math.min(translateX.get() + 10, bounds))
  //         if (translateX.get() !== bounds) {
  //           setUpdatesToAnnounce(
  //             vsprintf(
  //               props.i18nText.announcerI18nText.panI18nText
  //                 .imagePanAnnouncerText,
  //               [props.i18nText.announcerI18nText.panI18nText.leftText],
  //             ),
  //           )
  //         }
  //         break
  //       case 'ArrowRight':
  //         event.preventDefault()
  //         translateX.set(Math.max(translateX.get() - 10, -bounds))
  //         if (translateX.get() !== -bounds) {
  //           setUpdatesToAnnounce(
  //             vsprintf(
  //               props.i18nText.announcerI18nText.panI18nText
  //                 .imagePanAnnouncerText,
  //               [props.i18nText.announcerI18nText.panI18nText.rightText],
  //             ),
  //           )
  //         }
  //         break
  //       case 'ArrowUp':
  //         event.preventDefault()
  //         translateY.set(Math.min(translateY.get() + 10, bounds))
  //         if (translateY.get() !== bounds) {
  //           setUpdatesToAnnounce(
  //             vsprintf(
  //               props.i18nText.announcerI18nText.panI18nText
  //                 .imagePanAnnouncerText,
  //               [props.i18nText.announcerI18nText.panI18nText.upText],
  //             ),
  //           )
  //         }
  //         break
  //       case 'ArrowDown':
  //         event.preventDefault()
  //         translateY.set(Math.max(translateY.get() - 10, -bounds))
  //         if (translateY.get() !== -bounds) {
  //           setUpdatesToAnnounce(
  //             vsprintf(
  //               props.i18nText.announcerI18nText.panI18nText
  //                 .imagePanAnnouncerText,
  //               [props.i18nText.announcerI18nText.panI18nText.downText],
  //             ),
  //           )
  //         }
  //         break
  //     }
  //   }
  // }

  const zoomControlProps = getZoomControlProps(props, paginate)

  return (
    <FocusTrap active={fullScreenActive}>
      <Container
        data-testid="image-gallery"
        className={props.className}
        fullscreen={fullScreenActive}
        height={props.height}
        style={props.style}
        direction={props.direction}
        onKeyDown={(e) => {
          if (e.key === KeyboardKeys.Escape) {
            setFullScreenActive(false)
          }
          // if (scale > Scale.MIN) {
          //   keyboardPan(e)
          // }
        }}
        imageOverflow={props.imageOverflow}
      >
        <Header fullscreen={fullScreenActive}>
          {fullScreenActive && (
            <TitleWrapper>
              <Title
                ref={fullscreenTitleRef}
                fullscreen={fullScreenActive}
                tabIndex={headerTitleTabIndex}
                onBlur={() =>
                  setHeaderTitleTabIndex(
                    FullscreenHeaderTitleTabIndex.NotTabbable,
                  )
                }
              >
                {props.altText}
              </Title>
            </TitleWrapper>
          )}
          {/* <Controls {...controlProps} location={Position.TOP_LEFT} />
          <Controls {...controlProps} location={Position.TOP_RIGHT} /> */}
        </Header>
        <ContentWrapper
          direction={direction}
          fullScreen={fullScreenActive}
          gapSpacing={props.gapSpacing}
        >
          <CarouselWrapper
            direction={direction}
            fullscreen={fullScreenActive}
            height={props.height}
          >
            {showZoomButtonsAccessibilityLabel && (
              <AccessibilityLabel
                fullscreen={fullScreenActive}
                id="image-gallery-accessibility-label"
                data-testid="image-gallery-accessibility-label"
              >
                <AccessibilityLabelText>
                  {props.i18nText.accessibilityLabel}
                </AccessibilityLabelText>
              </AccessibilityLabel>
            )}
            {/* <Controls {...controlProps} location={Position.BOTTOM_LEFT} />*/}
            {props.zoomEnabled && (
              <Controls
                {...zoomControlProps}
                selectedImageIndex={selectedImageIndex}
                location={
                  props.carousel?.overflow
                    ? Position.TOP_MIDDLE
                    : Position.TOP_RIGHT
                }
                desktopZoomEnabled={props.desktopZoomEnabled}
              />
            )}
            {props.presentImageGalleryZoomModal && (
              <Carousel
                items={zoomCarouselItems}
                i18n={props.i18nText?.carouselI18nText}
                indicatorStyle="hidden"
                itemsPerSlide={props.itemsPerSlide || 1}
                hideSlidePreview={true}
                hideControls={true}
                loopSlides={true}
                slideTo={selectedImageIndex}
                isZoomModal={true}
                swipeable={scale === Scale.MIN}
                onChange={(index) => setSelectedImageIndex(index)}
                style={{ height: '100%' }}
                itemGapSpacing={props.carousel?.itemGapSpacing}
                controls={props.carousel?.controls}
                overflow={props.carousel?.overflow}
                onSwipe={props.onSwipe}
                onNavClick={props.onNavClick}
                onThumbnailClick={props.onThumbnailClick}
              />
            )}
            {!props.presentImageGalleryZoomModal && (
              <StyledImageCarousel
                urls={props.urls.zoom || props.urls.images}
                i18nText={props.i18nText?.carouselI18nText}
                ampProps={props.ampProps}
                altText={props.altText}
                slideTo={selectedImageIndex}
                zoomClickable={props.desktopZoomEnabled}
                presentImageGalleryZoomModal={
                  props.presentImageGalleryZoomModal
                }
                zoomOnClick={(index) =>
                  props.desktopZoomEnabled
                    ? zoomButtonClickHandler && zoomButtonClickHandler(index)
                    : undefined
                }
                onChange={(index) => setSelectedImageIndex(index)}
                fullscreen={fullScreenActive}
                aspectRatio={theme.patterns.imageGallery.image.aspectRatio}
                itemsPerSlide={props.itemsPerSlide}
                itemGapSpacing={props.carousel?.itemGapSpacing}
                controls={props.carousel?.controls}
                overflow={props.carousel?.overflow}
                onHydrateVisibleFetchPriority={
                  props.carousel?.onHydrateVisibleFetchPriority
                }
                onSwipe={props.onSwipe}
                onNavClick={props.onNavClick}
                onThumbnailClick={props.onThumbnailClick}
                isVideoSplitControls={containsVideo}
                imageClassName={props.imageClassName}
              />
            )}
          </CarouselWrapper>
          <ThumbnailsWrapper
            fullscreen={fullScreenActive}
            direction={direction}
            marginX={props.thumbnailsMarginX}
            onFocus={() => setShowZoomButtonsAccessibilityLabel(false)}
            thumbnailPadding={props.thumbnailPadding}
          >
            <ImageThumbnails
              urls={props.urls.thumbnails}
              selectedImageIndex={selectedImageIndex}
              onSelect={(index) => {
                setSelectedImageIndex(index)
                props.onThumbnailClick?.()
              }}
              thumbnailButtonAriaLabel={
                props.i18nText?.thumbnailButtonAriaLabel || ''
              }
              direction={direction}
              size={fullScreenActive ? [78, 150, 78] : props.thumbnailSize}
              altText={props.altText}
              fullScreen={fullScreenActive}
              aspectRatio={theme.patterns.imageGallery.image.aspectRatio}
            />
          </ThumbnailsWrapper>
          {isTranscriptOpen && (
            <VideoTranscriptModal
              i18nText={getTranslatedModalText(
                modalTitle,
                props.i18nText.closeI18nText,
              )}
              content={activeTranscript}
              onClose={() => setIsTranscriptOpen(false)}
            />
          )}
        </ContentWrapper>
        <Footer
          fullscreen={fullScreenActive}
          show={props.showFooter ?? [false]}
        >
          <Title fullscreen={fullScreenActive} data-testid="footer-index">
            {vsprintf(props.i18nText?.fullscreenIndexI18nText || '', [
              selectedImageIndex + 1,
              props.urls.zoom.length,
            ])}
          </Title>
          {props.urls.images.length > 1 &&
            !containsVideo &&
            carouselSlideControls}
        </Footer>
        {props.renderAnnouncer &&
          props.renderAnnouncer('assertive', updatesToAnnounce)}
      </Container>
    </FocusTrap>
  )
}
