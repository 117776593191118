import { pushToDataLayer } from '@thg-commerce/enterprise-metrics/src/data_layer'
import { pushButtonEventGA4 } from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import { BasketData } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Query/Basket/Basket'

interface OnCheckoutClickParams {
  executeCheckoutStart: () => void
  setSelectYourSampleModalOpen: (open: boolean) => void
  setSelectYourSampleInteracted: (interacted: boolean) => void
  selectYourSampleInteracted: boolean
  basket?: BasketData
  useGA4EnhancedEcom?: boolean
  eventText?: string
}

export const onCheckoutClick = ({
  executeCheckoutStart,
  setSelectYourSampleModalOpen,
  setSelectYourSampleInteracted,
  selectYourSampleInteracted,
  basket,
  useGA4EnhancedEcom,
  eventText,
}: OnCheckoutClickParams) => {
  pushToDataLayer({
    type: 'elysiumEvent',
    eventData: {
      eventAction: 'Go To Checkout',
      eventCategory: 'Link',
    },
  })

  if (useGA4EnhancedEcom) {
    pushButtonEventGA4({
      event_name: 'element_click',
      element_name: 'Checkout Button',
      element_location: eventText || '',
    })
  }

  if (basket?.selectYourSample.length) {
    if (!selectYourSampleInteracted) {
      setSelectYourSampleInteracted(true)
      setSelectYourSampleModalOpen(true)
      return
    }
    pushToDataLayer({
      type: 'elysiumEvent',
      eventData: {
        eventAction: 'Clicked to go straight to checkout',
        eventCategory: 'qualifiedFreeGiftModal',
      },
    })
  }
  executeCheckoutStart()
}
