import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { useEnterpriseContext } from '@thg-commerce/enterprise-core'
import { useBackendEventNormaliser } from '@thg-commerce/enterprise-metrics'
import {
  CheckoutStartData,
  CheckoutStartVariables,
} from '@thg-commerce/enterprise-network'
import { Feature } from '@thg-commerce/enterprise-network/src/generated/graphql'

import { Checkout as CHECKOUT_START_MUTATION } from '../../../../graphql/Mutation/Checkout.graphql'
import { useSiteDefinition } from '../../../ConfigurationLoader'
import { useLoginCheck } from '../../../CustomerContext'
import { EnterpriseContext } from '../../../EnterpriseContext'
import { Routes } from '../../../Routing/Routes'
import { useTransmit } from '../../../TransmitWrapper'

import { useBasket, useBasketId } from './../useBasketId'
import { CheckoutStartEventType } from './types'

export const useCheckoutStart = () => {
  const EnterpriseCtx = React.useContext(EnterpriseContext)
  const loggedIn = useLoginCheck()
  const { originUrl } = useSiteDefinition()
  const [basketId] = useBasketId()
  const { basket } = useBasket()
  const transmit = useTransmit()

  const { horizonFeatures } = useEnterpriseContext()

  const isSubscription =
    horizonFeatures?.includes(Feature.Subscriptions) &&
    (!!basket?.items.find((item) => item?.product?.isSubscription) ||
      !!basket?.items.find((item) => item?.subscriptionContract))

  const normaliseBackendEvent = useBackendEventNormaliser()
  const {
    value: [getExtensions],
  } = EnterpriseCtx.extensionsRef

  const [executeCheckoutStart, { data, loading }] = useMutation<
    CheckoutStartData,
    CheckoutStartVariables
  >(CHECKOUT_START_MUTATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const resultExtensions = data?.checkout?.extensions || getExtensions()

      transmit({
        type: 'checkout_start',
        payload: normaliseBackendEvent<CheckoutStartEventType>({
          basket,
          eventData: {
            subtype: 'standard_checkout',
            type: 'checkout_start',
          },
          rays: resultExtensions?.ray ? [resultExtensions.ray] : undefined,
          experiments: resultExtensions?.experiments,
          requestData: {
            ...(resultExtensions?.LoggerLinkData || {
              start_timestamp: Date.now(),
              duration_ms: 0,
            }),
            url: 'checkoutStart.account',
          },
        }),
      })
    },
  })
  const processing = React.useRef(false)

  React.useEffect(() => {
    if (data && !loading && processing.current) {
      processing.current = false
    }
  }, [data, loading])

  React.useEffect(() => {
    if (data && !data.checkout.error && data.checkout.checkoutUrl) {
      window.location.assign(data.checkout.checkoutUrl)
    }
  }, [data?.checkout.checkoutUrl])

  const execute = (
    paymentOption?: string,
    subPaymentOption?: string | null,
  ) => {
    if (!basketId || processing.current) return

    if (!loggedIn && window) {
      const loginURL = new URL(Routes.Login, originUrl)

      let returnTo = `${originUrl}${Routes.CheckoutStart}${
        paymentOption
          ? `?paymentOption=${paymentOption}${
              subPaymentOption ? `&subPaymentOption=${subPaymentOption}` : ''
            }`
          : ''
      }`

      if (isSubscription) {
        const checkoutURL = new URL(returnTo)
        const params = new URLSearchParams(checkoutURL.search)
        params.append('containsSubscriptionProduct', 'true')
        checkoutURL.search = params.toString()
        returnTo = checkoutURL.toString()
      }

      window.location.href = `${loginURL.toString()}?returnTo=${encodeURIComponent(
        returnTo,
      )}`
      return
    }

    processing.current = true

    executeCheckoutStart({
      variables: {
        input: {
          ...(paymentOption && {
            paymentOption: {
              option: paymentOption,
              subOption: subPaymentOption,
            },
          }),
          basketId,
          shippingDestination: EnterpriseCtx.shippingDestination.code,
          currency: EnterpriseCtx.currency,
        },
      },
    })
  }

  return {
    execute,
    data,
    loading,
  }
}
